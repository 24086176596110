$(function () {
    setHeaderScroll();
    hamburger();

});


//Lightcase
$(document).ready(function() {
	$('a[data-rel^=lightcase]').lightcase();

  	//Sub-menu
	var button_text = "Zobrazit navigaci";
	$('#page-detail__menu-mobile-trigger').on('click', function (e) {
		e.preventDefault();
		$(this).text(
			button_text == "Skrýt navigaci" ? "Zobrazit navigaci" : "Skrýt navigaci"
		);
		$('#page-detail__submenu').slideToggle(200);
		button_text = $(this).text();
	});


  $('.banner-hp').slick({
    dots: true,
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
    centerMode: true,
    initialSlide: 1,
    prevArrow: $('.slick__arrow.arrow__prev.banner__arrow'),
    nextArrow: $('.slick__arrow.arrow__next.banner__arrow')
  });

  $('#search_open').on('click', function(event) {
		event.preventDefault();
		$('.search-modal').addClass('shown');
	});
	$('.close-modal').on('click', function(e) {
		e.preventDefault();
		$('.search-modal').removeClass('shown');
	});

});
